<template>
  <div>
    <Navbar></Navbar>
    <div class="w-screen overflow-hidden flex items-center justify-center gap-8 flex-col mt-20 mb-10">
      <div v-for="(value, index) in cv" :key="'cv'+index">
        <router-link :to="'/cv/' + value.hash"
                     :class="value.visible === '0' ? 'text-red-900' : ''"
                     class="flex flex-col gap-2 hover:wb-text-green">
          <span class="text-lg font-inter font-normal">$ {{value.name}}</span>
          <span class="text-sm font-inter font-normal wb-text-gray">{{value.email}}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import nvgpost from "../common/nvgpost";


export default {
  name: "CVs",
  components: {Navbar},
  data() {
    return {
      cv: []
    }
  },
  mounted() {
    nvgpost("https://"+this.$root.$apiEndpoint+"/v1/all/cv",
        "POST",
        {
          'user_id': "local_5",
        },
        {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
          if(res.jwt) {
            this.cv = res.hashes
          }
        }, true)
  }
}
</script>