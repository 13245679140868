<template>
  <div>
    <Navbar v-if="!isLoad"></Navbar>
    <div v-if="!isLoad"
        class="overflow-hidden w-screen h-screen flex justify-center items-center gap-10 flex-col">
      <label>Load CV .pdf, .doc, .docx</label>
      <input id="cv" type="file" accept=".pdf,.doc,.docx" multiple="true" @change="isFileCheck">
      <div class="inline-block w-64" v-if="isFile">
        <Button @wasClicked="uploadCV">Upload</Button>
      </div>
    </div>
    <div v-else
         class="overflow-hidden w-screen h-screen flex justify-center items-center gap-10 flex-col">
      <div class="lds-ripple"><div></div><div></div></div>
    </div>
  </div>
</template>

<script>
import Button from "../components/Inter/Button";
import nvgpost from "../common/nvgpost";
import router from "../router";
import Navbar from "../components/Navbar";


export default {
  name: "Upload",
  components: {Navbar, Button},
  data() {
    return {
      isFile: false,
      pdfers: [],
      isLoad: false,
    }
  },
  mounted() {
    this.isFileCheck()
  },
  methods: {
    isFileCheck: function () {
      this.isFile = !!document.getElementById("cv").files.length
    },
    uploadCV: function () {
      let that = this
      let selectedFiles = document.getElementById("cv").files
      for(let i = 0; i < selectedFiles.length; i++) {
        let selectedFile = selectedFiles[i]
        let reader = new FileReader()

        reader.onload = function(event) {
          that.pdfers.push(event.target.result)
        }
        setTimeout(function(){
          reader.readAsDataURL(selectedFile)
        },150)
      }

      this.isLoad = true

      setTimeout(function () {
        nvgpost("https://"+that.$root.$apiEndpoint+"/v1/cv/upload",
            "POST",
            {
              'files': that.pdfers,
              'user_id': "local_5",
            },
            {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
              if(res.jwt && res.cv) {
                that.isLoad = false
                router.push("/cv")
              }
            }, true)
      }, 1500 * selectedFiles.length)
    }
  }
}
</script>