<template>
  <div>
    <Navbar></Navbar>

    <div class="text-sm mx-12 my-32">
      <div class="p-3 mt-8"
          v-for="(vac, index) in Object.keys(candidates)" :key="'vac' + index">
        <div>{{vac}}</div>
        <ul class="block ml-16 list-disc">
          <li v-for="(man, indexman) in candidates[vac]" :key="'man' + indexman">
            <router-link class="wb-text-blue" :to="'cv/'+man.hash">
              {{man.name}}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import nvgpost from "../common/nvgpost";


export default {
  name: "Result",
  components: {Navbar},
  data() {
    return {
      candidates: {}
    }
  },
  mounted() {
    nvgpost("https://"+this.$root.$apiEndpoint+"/v1/get/candidates",
        "POST",
        {
          'user_id': "local_5",
        },
        {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
          if(res.jwt) {
            this.candidates = res.candidates
          }
        }, true)
  },
}
</script>