<template>
  <div id="app">
    <router-view :key="randomId" />
  </div>
</template>

<script>
import userInfo from "@/common/userInfo"
import getUserInfo from "@/common/getUserInfo"
import md5 from 'md5/md5'


export default {
  name: 'App',
  computed: {
    randomId () {
      return this.$route.fullPath + new Date()
    }
  },
  mounted() {
    if((!userInfo.userHash || !userInfo.email)
        || (userInfo.userHash
          && md5(userInfo.email+userInfo.jwt) !== userInfo.userHash)) {
        if(localStorage.getItem("nvg_auth"))
          getUserInfo()
        else
        {
          setTimeout(function () {
            getUserInfo()
          },500)
        }
    }
  },
}
</script>