import Vue from "vue"
import VueRouter from "vue-router"
import Login from "@/views/auth/Login";
import Upload from "../views/Upload";
import CV from "../views/CV";
import CVs from "../views/CVs";
import Result from "../views/Result";
import Cards from "@/views/Cards";


Vue.use(VueRouter)
const router = new VueRouter({
    mode: "history",
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: "/",
            component: Login
        },
        {
            path: "/upload",
            component: Upload
        },
        {
            path: "/cv",
            component: CVs
        },
        {
            path: "/cv/:cvId",
            component: CV
        },
        {
            path: "/candidates",
            component: Result
        },
        {
            path: "/cards",
            component: Cards
        },

        { path: '/:catchAll(.*)', redirect: '/' }
    ]
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['',]
    let turl = to.path.split("/")[1]
    const authRequired = !publicPages.includes(turl)
    const loggedIn = !!localStorage.getItem('nvg_auth')

    let temp = localStorage.getItem("backcomeUrl")

    if (authRequired && !loggedIn)
    {
        localStorage.setItem("backcomeUrl", to.path)
        return next('/')
    }
    else if (temp && !!loggedIn)
    {
        localStorage.removeItem("backcomeUrl")
        return next(temp)
    }
    else if (['', ].includes(turl) && !!loggedIn)
        return next('/upload')
    next()
})

export default router