import Vue from 'vue'
import App from './App.vue'
import userInfo from "@/common/userInfo"
import router from '@/router'
import './assets/css/styles.css'
import './index.css'

Vue.config.productionTip = false
Vue.prototype.$apiEndpoint = 'rocman.nvg-group.com/api'

new Vue({
    router,
    render: h => h(App),
  }).$mount('#app')

userInfo.userHash = localStorage.getItem("usrha")
userInfo.email = localStorage.getItem("email")
userInfo.uid = localStorage.getItem("uid")
userInfo.jwt = localStorage.getItem("nvg_auth")