<template>
  <div>
    <Navbar></Navbar>

    <div class="grid grid-cols-3 my-32">
      <div class="col-start-2">
        <Button size="Lg" @wasClicked="addCard()">New Vacancy Search</Button>
        <Button size="Sm" class="mt-3" @wasClicked="SaveCards()">Save</Button>

        <div class="flex items-center justify-start flex-col gap-10 mt-8">
          <div v-for="(vac, index) in cards" :key="'vac'+index">
            <hr>
            <label>External ID</label>
            <input class="mt-2 px-3 py-3 border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm
                  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-15"
                   placeholder="external id"
                   v-model="vac.vacancy_id" />
            <label>Vacancy</label>
            <input class="mt-2 px-3 py-3 border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm
                  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-15"
                   placeholder="Vacancy"
                   v-model="vac.vacancy" />
            <input hidden class="hidden" v-model="vac.id" />
            <label class="inline-block mt-5">Words substr</label>
            <textarea v-model="vac.substr" rows="4" class="mt-2 border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm
                  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Inter/Button";
import Navbar from "@/components/Navbar";
import nvgpost from "@/common/nvgpost";


export default {
  name: "Cards",
  components: {Button, Navbar},
  data() {
    return {
      cards: []
    }
  },
  created() {
    nvgpost("https://"+this.$root.$apiEndpoint+"/v1/get/cards",
        "POST",
        {
          'user_id': "local_5",
        },
        {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
            if(res) {
              if(res.cards)
                this.cards = Object.values(res.cards)
            }
        }, true)
  },
  methods: {
    addCard: function () {
      this.cards.push({
        id: false,
        vacancy_id: Math.round(Math.random()*10000),
        vacancy: "",
        substr: "",
      })
    },
    SaveCards: function () {
      nvgpost("https://"+this.$root.$apiEndpoint+"/v1/save/cards",
          "POST",
          {
            'cards': this.cards,
            'user_id': "local_5",
          },
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, ()=>{}, true)
    }
  }
}
</script>