<template>
  <div class="container mx-auto p-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 p-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="flex-auto px-4 lg:px-10 py-10">
            <div class="relative w-full mb-3">
              <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
              >
                Email
              </label>
              <Input
                  type="email"
                  id="email"
                  :vkeyup="logInWithrocman"
                  placeholder="Email"
              />
            </div>

            <div class="relative w-full mb-3">
              <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
              >
                Password
              </label>
              <Input
                  type="password"
                  id="password"
                  :vkeyup="logInWithrocman"
                  placeholder="Password"
              />
            </div>

            <div class="text-center mt-6">
              <Button
                  @wasClicked="logInWithrocman()"
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import nvgpost from "@/common/nvgpost";
import getUserInfo from "@/common/getUserInfo";
import router from "@/router";
import Input from "@/components/Inter/Input";
import Button from "@/components/Inter/Button";


export default {
  name: 'Login',
  components: {
    Input, Button,
  },
  methods: {
    async logInWithrocman()  {
      let email = document.getElementById("email").value
      let password = document.getElementById("password").value

      if(email && password) {
        nvgpost("https://"+this.$root.$apiEndpoint+"/v1/auth",
            "POST",
            {
              'email': email,
              'password': password,
            },
            {}, (res)=>{
              if(res.jwt) {
                let jwt = res.jwt
                localStorage.setItem("nvg_auth",jwt)
                getUserInfo(jwt)
                router.push("/upload")
              }
              else {
                this.$root.$emit("toShowTrigger", "Login error!", 2)
              }
            }, true)
      }
    },
  }
}
</script>