<template>
  <input class="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm
                  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
         :id="this.id"
         :style="'width: ' + this.width + 'px'"
         :class="itype === 'Sm' ? ' px-3 py-3' : ' px-3 py-3'" @input="onchangefunc($event, farg1)"
         :type="type"
         :placeholder="this.placeholder" v-model="valueOfType"
         v-on:keyup.enter="vkeyup()">
</template>

<script>
export default {
  name: "Input",
  props: {
    id: String,
    placeholder: String,
    type: String,
    itype: String,
    width: Number,
    farg1: [String, Number, Array, Object],
    watchFunction: Function,
    onchangefunc: {
      type: Function,
      default: () => {

      }
    },
    vkeyup: Function,
  },
  data() {
    return {
      valueOfType: ""
    }
  },
  watch: {
    valueOfType: function (value) {
      if(this.watchFunction) {
        this.watchFunction(value, this.id)
      }
    },
  }
}
</script>
