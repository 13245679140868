<template>
  <div class="fixed top-0 left-0 w-full h-16 bg-lime-600 z-10 flex items-center justify-center gap-4">
    <router-link class="text-white hover:wb-text-black" to="/upload">Upload</router-link>
    <router-link class="text-white hover:wb-text-black" to="/cv">All CV</router-link>
    <router-link class="text-white hover:wb-text-black" to="/cards">Cards</router-link>
    <router-link class="text-white hover:wb-text-black" to="/candidates">Candidates</router-link>
<!--    <router-link class="text-white hover:wb-text-black" to="/result">Result</router-link>-->
  </div>
</template>

<script>
export default {
  name: "Navbar"
}
</script>