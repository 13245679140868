import router from "@/router"

let logout = function (autot) {
    localStorage.removeItem("nvg_auth")
    localStorage.removeItem("email")
    localStorage.removeItem("usrha")
    localStorage.removeItem("uid")
    if(autot===true)
        router.push("/")
}

export default logout