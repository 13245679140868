<template>
  <div class="pb-64">
    <Navbar></Navbar>

    <h1 class="mt-32 text-4xl text-center">Check & verify data please 🥰</h1>
    <div class="mt-4 w-64 mx-auto">
      <Button @wasClicked="savecv()">It's Ok. Save.</Button>
      <Button @wasClicked="archcv()" class="mt-8" bg="bg-red-900">
        <span v-if="cv[0].visible === '1'">Delete (Archive)</span>
        <span v-else>Restore</span>
      </Button>
    </div>

    <div class="overflow-x-scroll">
      <div class="grid grid-cols-17 mt-8 mx-24 font-bold w150vw">
        <div class="border p-5">Name</div>
        <div class="border p-5">Phone</div>
        <div class="border p-5">Email</div>
        <div class="border p-5">Experience</div>
        <div class="border p-5">Age</div>
        <div class="border p-5">DOB</div>
        <div class="border p-5">Sex</div>
        <div class="border p-5">Drive</div>
        <div class="border p-5">Langs</div>
        <div class="border p-5">Hobby</div>
        <div class="border p-5">Social media</div>
        <div class="border p-5 col-span-3">Full Text</div>
        <div class="border p-5">Images</div>
        <div class="border p-5">Vacancies</div>
        <div class="border p-5">Filename</div>
      </div>
      <div v-for="(value, index) in cv" :key="'cv' + index"
           class="grid grid-cols-17 mt-2 mx-24 w150vw">
        <div class="border p-5">
          <Input :value="value.name" :onchangefunc="changeData" :id="'0_name'" />
        </div>
        <div class="border p-5">
          <Input :value="value.phone" :id="'0_name'" :onchangefunc="changeData" />
        </div>
        <div class="border p-5">
          <Input :value="value.email" :id="'0_email'" :onchangefunc="changeData" />
        </div>
        <div class="border p-5">
          <Input :value="value.exp" :id="'0_exp'" :onchangefunc="changeData" />
        </div>
        <div class="border p-5">
          <Input :value="value.age" :id="'0_age'" :onchangefunc="changeData" />
        </div>
        <div class="border p-5">
          <Input :value="value.dob" :id="'0_dob'" :onchangefunc="changeData" />
        </div>
        <div class="border p-5">
          <Input :value="value.sex" :id="'0_sex'" :onchangefunc="changeData" />
        </div>
        <div class="border p-5">
          <Input :value="value.drive" :id="'0_drive'" :onchangefunc="changeData" />
        </div>
        <div class="border p-5">
          <Input :value="value.langs" :id="'0_langs'" :onchangefunc="changeData" />
        </div>
        <div class="border p-5">
          <Input :value="value.hobby" :id="'0_hobby'" :onchangefunc="changeData" />
        </div>
        <div class="border p-5">
          <Input :value="value.socnets" :id="'0_socnets'" :onchangefunc="changeData" />
        </div>
        <div class="border p-5 col-span-3">
          <Input :value="value.text" :id="'0_text'" :onchangefunc="changeData" />
        </div>
        <div class="border p-5 overflow-hidden flex items-start justify-start flex-col gap-4">
          <img class="object-cover max-h-16" :src="'/api/img/'+image" alt="#" v-for="(image, indexiage) in JSON.parse(value.images)" :key="'aa'+indexiage">
        </div>
        <div class="border p-5">
          <Input :value="value.vacancies" :id="'0_vacancies'" :onchangefunc="changeData" />
        </div>
        <div class="border p-5">
          <a class="text-blue-400" :href="'https://rocman.nvg-group.com/api/'+value.filename">{{value.filename}}</a>
        </div>

      </div>
    </div>

    <div class="mt-4 w-64 mx-auto">
      <Button @wasClicked="savecv()">It's Ok. Save.</Button>

      <Button @wasClicked="archcv()" class="mt-8 bg-red-900">
        <span v-if="cv[0].visible === '1'">Delete (Archive)</span>
        <span v-else>Restore</span>
      </Button>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import nvgpost from "../common/nvgpost";
import Button from "../components/Inter/Button";
import router from "../router";
import Input from "@/components/Inter/Input";


export default {
  name: "CV",
  components: {Input, Button, Navbar},
  data() {
    return {
      cv: [
        {
          visible: false
        }
      ],
      cvId: this.$route.params.cvId,
      labels: []
    }
  },
  mounted() {
    nvgpost("https://"+this.$root.$apiEndpoint+"/v1/get/cv",
        "POST",
        {
          "hash": this.cvId,
        },
        {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
          if(res.jwt) {
            this.cv = res.cv
          }
        }, true)
  },
  methods: {
    archcv: function () {
      nvgpost("https://"+this.$root.$apiEndpoint+"/v1/arch/cv",
          "POST",
          {
            "cv": this.cv,
            'user_id': "local_5",
          },
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
            if(res.jwt) {
              router.push("/result")
            }
          }, true)
    },
    savecv: function () {
      nvgpost("https://"+this.$root.$apiEndpoint+"/v1/save/cv",
          "POST",
          {
            "cv": this.cv,
            'user_id': "local_5",
          },
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
            if(res.jwt) {
              router.push("/result")
            }
          }, true)
    },
    changeData: function (event) {
      let a = event.target.id.split("_")
      let id = a[0]
      let type = a[1]
      this.cv[id][type] = event.target.value
    }
  }
}
</script>

<style scoped>
  .w150vw {
    width: 300vw;
  }
</style>