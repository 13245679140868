import nvgpost from "@/common/nvgpost"
import md5 from "md5"
import userInfo from "@/common/userInfo"
import Vue from "vue"


let getUserInfo = function (mjwt, tryyer) {

    if(tryyer===undefined)
    {
        setTimeout(function () {
            getUserInfo(mjwt, 2)
        },3000)
    }

    mjwt = mjwt === undefined ? userInfo.jwt : mjwt

    nvgpost("https://"+Vue.prototype.$apiEndpoint+"/v1/users/info",
        "POST",
        {},
        {'Authorization': 'Bearer '+mjwt,}, (res)=> {
            if(res.email === undefined)
                console.log("Some error")

            if(res.email !== undefined) {
                localStorage.setItem("email",res.email)
                localStorage.setItem("uid",res.uid)
                localStorage.setItem("usrha",md5(res.email+mjwt))

                userInfo.userHash = localStorage.getItem("usrha")
                userInfo.email = localStorage.getItem("email")
                userInfo.uid = localStorage.getItem("uid")
                userInfo.jwt = localStorage.getItem("nvg_auth")
            }
        })
    
}

export default getUserInfo